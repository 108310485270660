<template>
  <div class="wrapper">
    <!-- 修改删除加工单 -->
    <header>{{ mytitle_bb }}</header>
    <p></p>
    <div class="split" v-if="false">
      <el-row :gutter="1">
        <el-col :span="4"><span class="mypmhh">筛选</span></el-col>
        <el-col :span="18"><el-input v-model="inputVal" @input="hh_inputChange"></el-input></el-col>
      </el-row>
    </div>
    <!-- 中间滚动区域 -->
    <section>
      <div class="mybody">
      <lzqTable 
        :tableData_hh_dh="tableData_xm_gx" :mytitle="mytitle_bb" 
        :IsSelection="true" RowKey="item3"
        @tableRowClick="tableRowClick($event)"
        @handleSelectionChange="handleSelectionChange($event)"
      >
      </lzqTable>
      </div>
    </section>
        <!-- 中间图文列表开始 -->
      <el-scrollbar v-if="false">
        <div class="flex"
        :style="`background-color: ${item.item10};`"
        style="width: 100%; border-top: 1px  solid #999;" v-for="(item, index) in tableData_xm_gx" :key="index">
            <div class="text-sl text-gray-800 " v-if=" route.query.bmmc =='查询纸采'">
              货号{{ item.item1 }}/英文品名{{item.item2}}/盒装{{ item.item3 }}/箱装{{item.item4}}/
              应送货数{{item.item5}}/工厂单号{{item.item6}}/客人单号{{item.item7}}/长宽高{{item.item8}}
              /采购日期{{ item.item9 }}
            </div>
            <div class="text-sl text-gray-800 " v-if="route.query.bmmc == '查询气采'">
                货号:{{ item.item1 }}/气泡尺寸:{{ item.item2 }}/未送货数:{{ item.item3 }}/采购日期:{{ item.item4 }}/工厂单号:{{ item.item5 }}/客人单号:{{ item.item6 }}
            </div>
            <div class="text-sl text-gray-800 " v-if="route.query.bmmc == '查库位'">
                {{ item.item1 }}
            </div>
        </div>
      </el-scrollbar>
    <!-- 中间图文列表结束 -->
    <!-- 中间单据弹窗开始 -->
    <el-dialog v-model="dialogFormVisible_dj" width="90%">
      <el-row justify="center">
        <el-col :span="10"><span class="mybt">{{formData.pm}}</span></el-col>
      </el-row>
      <el-form>
        <div style="margin-top: 10px;margin-bottom: 10px;">
          <el-form-item label="客户名称" label-width="90px"><el-input disabled v-model="formData.khmc" style="width:200px" /> </el-form-item>
          <el-form-item label="品名" label-width="90px"><el-input disabled v-model="formData.pm" style="width:200px" /> </el-form-item>
          <el-form-item label="单位" label-width="90px"><el-input v-model="formData.dw" style="width:200px" /> </el-form-item>
          <el-form-item label="数量" label-width="90px"><el-input type="number" v-model="formData.sl" style="width:200px" /> </el-form-item>
          <el-form-item label="单价" label-width="90px"><el-input type="number" v-model="formData.dj" style="width:200px" /> </el-form-item>
          <el-form-item label="金额" label-width="90px"><el-input type="number" v-model="formData.je" style="width:200px" /> </el-form-item>
          <el-form-item label="送货地址" label-width="90px"><el-input type="number" v-model="formData.shdz" style="width:200px" /> </el-form-item>
        </div>
      </el-form>
      <el-row justify="center">
        <el-col :span="10"><el-button type="success" @click="my_save_dj" :loading="myloading">保存{{formData.qxxm}}</el-button></el-col>
      </el-row>
    </el-dialog>
    <!-- 中间单据弹窗结束 -->
    <!-- 报表查询条件中间弹窗开始 -->
    <el-dialog v-model="dialogFormVisible_bbtj" width="90%">
      <div class="mypm" style="color: red">
        {{ mytitle_bb }}
      </div>
      <el-form>
        <el-form-item>
          <el-date-picker v-model="formData.rq" type="date" placeholder="开始日期" format="YYYY-MM-DD"
            value-format="YYYY-MM-DD" style="width: 145px"></el-date-picker><span>到</span>
          <el-date-picker v-model="formData.xdrq" type="date" placeholder="结束日期" format="YYYY-MM-DD"
            value-format="YYYY-MM-DD" style="width: 145px"></el-date-picker>

          <el-button size="small" type="primary" @click="getmy_date(1)">上月</el-button>
          <el-button size="small" type="danger" @click="getmy_date(4)">前7日</el-button>
          <el-button size="small" type="success" @click="getmy_date(2)">本月</el-button>
          <el-button size="small" type="warning" @click="getmy_date(3)">本年</el-button>
        </el-form-item>

        <div v-if="isShowKwSelect">
          <el-form-item label="类别" :label-width="formLabelWidth">
            <el-select v-model="formData.bmck" collapse-tags style="width: 150px" @change="SelectOption_kwjs" size="small"
              placeholder="类别" no-data-text="暂无数据">
              <el-option v-for="item in kw_options" :key="item.item1" :label="item.item1" :value="item.item1" />
            </el-select>
          </el-form-item>
        </div>

        <div v-if="isShowCKTJ1">
          <el-form-item label="仓库" :label-width="formLabelWidth">
            <el-select v-model="formData.bmck" collapse-tags style="width: 150px" @change="SelectOption_js" size="small"
              placeholder="仓库名称" no-data-text="暂无数据">
              <el-option v-for="item in jsbm_options" :key="item.item1" :label="item.item1" :value="item.item1" />
            </el-select>
          </el-form-item>
        </div>

        <div v-if="isShowTJ1">
          <el-form-item :label="cxtjLable1" :label-width="formLabelWidth" @click="showkhdraw">
            <el-input v-model="formData.khmc" autocomplete="off" style="width: 150px"></el-input>
          </el-form-item>
        </div>
        <div v-if="isShowTJ2">
          <el-form-item :label="cxtjLable2" :label-width="formLabelWidth">
            <el-input v-model="formData.pm" autocomplete="off" style="width: 150px"></el-input>
          </el-form-item>
        </div>
        <div v-if="isShowTJ3">
          <el-form-item :label="cxtjLable3" :label-width="formLabelWidth">
            <el-input v-model="formData.shdz" autocomplete="off" style="width: 150px"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="success" style="margin: 2px" @click="my_cxbb" :loading="myloading">查询报表</el-button>
          <el-button type="danger" @click="dialogFormVisible_bbtj = !dialogFormVisible_bbtj">取消查询</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 报表查询条件中间弹窗结束 -->

    <!-- 右弹客户资料开始 -->
    <el-drawer v-model="drawer_RtoL_kh" title="查看客户" :with-header="false" size="90%">
      <el-button type="danger" @click="drawer_RtoL_kh = !drawer_RtoL_kh">关闭</el-button>
      <lzqTable :tableData_hh_dh="tableData_ckrbb" 
      mytitle="手机查汇总加工单时调用加工客户资料表头" :IsSelection="false" RowKey="item1"
        :Myheight="MYheight2" @tableRowClick="khmclist_Click($event)"></lzqTable>
    </el-drawer>
    <!-- 右弹客户资料结束 -->
    <!-- 手机端查询单个品名质量分析报表从右到左弹窗开始 -->

    <el-drawer v-model="drawer_dgzlb" title="查询单个品名质量分析报表" :with-header="false" size="90%">
      <lzqTable :tableData_hh_dh="tableData_ckrbb" :mytitle="dgmxtitle" :IsSelection="false" RowKey="item1"
      :Myheight="MYheight2" @tableRowClick="drawer_dgzlb = !drawer_dgzlb">
      </lzqTable>
      <el-button size="small" type="success" style="margin: 2px" @click="cxmx_daymonthyear('查询每日')"
        :loading="myloading">本品每日</el-button>
      <el-button size="small" type="danger" style="margin: 2px" @click="cxmx_daymonthyear('查询整月')"
        :loading="myloading">本品每月</el-button>
      <el-button size="small" type="warning" style="margin: 2px" @click="cxmx_daymonthyear('查询整年')"
        :loading="myloading">所有产品整年</el-button>
      <div class="mypm" style="color: red"> {{ formData.pm }} </div>
    </el-drawer>
    <!-- 手机端查询单个品名质量分析报表从右到左弹窗结束 -->

    <!-- 查看明细从右到左弹窗开始 -->
    <el-drawer v-model="drawer_RtoL" title="查看明细" :with-header="false" size="90%">
      <el-button type="danger" @click="drawer_RtoL = !drawer_RtoL">关闭</el-button>
      <!-- <el-button type="warning" @click="ckrbb_bsmx">完工明细</el-button> -->
      <el-tabs v-model="activeName" type="card" class="demo-tabs" @tab-click="tabshandleClick">
        <el-tab-pane label="发货" name="发货">
          <lzqTable :tableData_hh_dh="tableData_xdmx" mytitle="点击汇总报表查询发货明细" :IsSelection="false" RowKey="item8"
            :Myheight="MYheight2" @tableRowClick="drawer_RtoL = !drawer_RtoL"></lzqTable>
        </el-tab-pane>
        <el-tab-pane label="收款" name="收款">
          <lzqTable :tableData_hh_dh="tableData_bsmx" mytitle="点击汇总报表查询收款明细" :IsSelection="false" RowKey="item8"
            :Myheight="MYheight2" @tableRowClick="drawer_RtoL = !drawer_RtoL"></lzqTable>
        </el-tab-pane>
      </el-tabs>


    </el-drawer>
    <!-- 查看明细从右到左弹窗结束 -->
    <!-- 底部按钮开始 -->
    <footer>
      <div class="mybutton">
        <el-row>
          <el-button type="success" @click="dialogFormVisible_bbtj = !dialogFormVisible_bbtj">条件</el-button>
          <el-button type="warning" @click="btn_add_xg_del('修改')">修改</el-button>
          <el-button type="danger" @click="btn_add_xg_del('删除')">删除</el-button>
          <!-- <el-button type="success" @click="toexcel">导到excel表</el-button> -->
          <el-button type="info" @click="toHome">主页</el-button>
        </el-row>
      </div>
    </footer>
    <!-- 底部按钮结束 -->
  </div>
</template>

<script setup>
import * as XLSX from 'xlsx';
import myGtdatafun from "../../myapi/myGtdatafun.js"; //获取数据函数
import dateglobalfun from "../../../dateglobalfun.js"; //日期函数
import { ref, reactive, onMounted, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessageBox, ElNotification } from "element-plus";
import lzqTable from "../truecomponents/lzqTable.vue"; //引入自定义组件
import lzqformDrawer from "../truecomponents/formDrawer.vue"; //引入自定义组件

const isShowCKTJ1 = ref(false); //是否显示仓库条件1
const isShowKwSelect = ref(false); //是否显示库位选择条件1
const isShowTJ1 = ref(true); //是否显示条件1
const isShowTJ2 = ref(true); //是否显示条件2
const isShowTJ3 = ref(true); //是否显示条件3
const formLabelWidth = "120px";
const drawer_RtoL = ref(false);
const drawer_RtoL_kh = ref(false);
const lzqformDrawer_ref = ref();
const drawer_dgzlb = ref(false);//单个质量分析报表
const tableData_xdmx = reactive([]); //注意这个定义,搞得半死!!
const tableData_ckrbb = reactive([]); //注意这个定义,搞得半死!!
const tableData_ckrbb_base = reactive([]); //注意这个定义,搞得半死!!
const tableData_bsmx = reactive([]); //注意这个定义,搞得半死!!
const dialogFormVisible = ref(false);
const dialogFormVisible_bbtj = ref(false);
const cxtjLable1 = ref("");
const cxtjLable2 = ref("");
const cxtjLable3 = ref("");
const mytitle_bb = ref(""); //报表标头
const mytitle_mx = ref(""); //明细表标头
const dialogFormVisible_dj = ref(false);
const dgmxtitle = ref("手机端查询单个品名质量分析报表右弹表头"); //单个明细表标头
const MYheight2 = ref(600);
const jsbm_options = reactive([]); //注意这个定义,搞得半死!!
const kw_options = reactive([]); //注意这个定义,搞得半死!!
let route = useRoute();
const activeName = ref('发货')
const myloading = ref(false);
const xsSelectOptions = [
  {
    value: '手机端查询产品销售金额排名',
    label: '手机端查询产品销售金额排名',
  },
  {
    value: '手机端查询客户销售金额排名',
    label: '手机端查询客户销售金额排名',
  },
  {
    value: '手机端查询客户产品销售金额排名',
    label: '手机端查询客户产品销售金额排名',
  }
]
const handleSelectionChange = (arr) => {//选择复选框
  console.log('handleSelectionChange[]', arr);
  // let ids = "";
  arr.forEach((item, index) => {
    formData.value.khmc = item.item2
    formData.value.pm = item.item3
    formData.value.dw = item.item4
    formData.value.sl = item.item5
    formData.value.dj = item.item6
    formData.value.je = item.item7
    formData.value.shdz = item.item8
    formData.value.id = item.item11
    // if (index == 0) {
    //   ids = item.item3;
    // } else {
    //   ids = ids + "," + item.item3;
    // }
  });
  console.log("handleSelectionChange", formData.value);
}
const btn_add_xg_del = (tj) => {//三个按钮的操作
  switch (tj) {
    case "修改":
      if(formData.value.pm == ""){return}
      formData.value.qxxm = "修改"
      dialogFormVisible_dj.value=true//显示弹窗
      break;
    case "删除":
      if(formData.value.pm == ""){return}
      my_del()//删除
      break; 
  }
}

const my_del = () =>{//删除一行品名
  ElMessageBox.confirm(
    "[" + formData.value.khmc + "/" + formData.value.pm + "/" + formData.value.dw+ "/" + formData.value.dj + "/" + "]修改或删除?",
    "修改或删除?",
    { confirmButtonText: "确认删除", cancelButtonText: "取消删除", type: "error", }
  )
    .then(() => {
      formData.value.cztj = "手机端修改一行加工单";
      myGtdatafun
        .MygettableData("html_dysj_ZSG.php", formData.value)
        .then(function (response) {
          console.log(response);
          location.reload(); //重新加载本页
        });
      tableData_xm_gx.splice(
        tableData_xm_gx.findIndex((e) => e.item4 === formData.value.id),
        1
      ); // 将删除id等于1的选项
    })
    .catch(() => {
      console.log('取消删除');
      // dialogFormVisible_dj.value = !dialogFormVisible_dj.value;
      // formData.value.khmc=row.item1
      // formData.value.shdz=row.item2
    });
}

const my_add_xg_del_pm = () => {//保存品名新增和修改
  console.log('my2_add_xg',formData.value);
  if (formData.value.pm == "") {
    ElMessage({ message: '品名不能为空,保存不成功!', type: 'error', });
    return;
  }
  //客户名称重复了,不保存
  let cansavekh = 'yes';
  if(formData.value.qxxm == "新增"){
    tableData_xm_gx.forEach((ite, index) => {
      if (ite.item1 == formData.value.pm) {
        ElMessage({ message: '品名重复了,保存不成功!', type: 'error', });
        cansavekh = 'no';
      }
    });
  }
  if (cansavekh == 'no') {
    dialogFormVisible_pm.value = false;//隐藏弹窗
    formData.value.pm = ""; formData.value.dw = ""; formData.value.dj = "";
    return;
  }
  myloading.value = true
  if(formData.value.qxxm == "新增"){formData.value.cztj = "手机端新增加工品名资料";}//==要注意
  if(formData.value.qxxm == "修改"){formData.value.cztj = "手机端修改加工品名资料";}
  khjczl_get();
  dialogFormVisible_pm.value = false;
  formData.value.khmc = ""; formData.value.shdz = "";
} 
const my_save_dj = () => {//保存单据
  console.log('m1y_add_xg',formData.value);
  let cansavekh = 'yes';
  if (formData.value.sl == "" && formData.value.je == "") {
    ElMessage({ message: '数量金额不能都为空,保存不成功!', type: 'error', });
    return;
  }
  if (formData.value.sl == "") {formData.value.sl =0}
  if (formData.value.je == "") {formData.value.je =0}
  myloading.value = true
  // formData.value.cztj = "手机端保存加工单输入的数量单价等";
  // khjczl_get();
} 

const showkhdraw =() =>{
  formData.value.cztj="手机查汇总加工单时调用加工客户资料"
  //lzqformDrawer_ref.value.open()//显示客户列表
  get_gwgx_cxkh()
  console.log('显示客户列表');
}
const khmclist_Click =(row) =>{
  formData.value.khmc=row.item1
  drawer_RtoL_kh.value=false//关闭客户列表
  if (route.query.bmmc == "查加工单") {
    mytitle_bb.value = "查询加工单报表表头"
    formData.value.cztj = "手机端查询汇总加工单";
  }
}
const tableRowClassName = (row, rowIndex) => {
  if (route.query.bmmc == "手机端查询产品质量报表") {
    const newrow = JSON.parse(JSON.stringify(row))
    // console.log(newrow, newrow.row.item9);
    if (newrow.row.item9 == '黄色' || newrow.row.item5 == '黄色') {
      return 'warningrow'
    } else if (newrow.row.item9 == '红色' || newrow.row.item5 == '红色') {
      return 'redrow'
    }
  }

  if (route.query.bmmc == "手机端查询销售报表") {
    const newrow = JSON.parse(JSON.stringify(row))
    // console.log(newrow, newrow.row.item9);
    if (newrow.row.item7 == '黄色') {
      return 'warningrow'
    } else if (newrow.row.item7 == '红色') {
      return 'redrow'
    }
  }
  return ''
}

const tabshandleClick = (tab, event) => {
  console.log(activeName.value);
}

const SelectOption_js = (Optionvalue) => {//仓库
  formData.value.bmck = Optionvalue;
};
const SelectOption_kwjs = (Optionvalue) => {//仓库类别
  console.log('仓库类别 :>> ', Optionvalue);
  formData.value.bmcb = Optionvalue;
};

//单个明细报表中查询每日整月整年三种报表
const cxmx_daymonthyear = (daymonthyear) => {
  formData.value.cztj = "手机端查询单个品名质量分析报表" + daymonthyear;
  if (daymonthyear == '查询整月' || daymonthyear == '查询整年') {
    dgmxtitle.value = "手机端查询单个品名质量分析报表每月每年"
  }
  if (daymonthyear == '查询每日') {
    dgmxtitle.value = "手机端查询单个品名质量分析报表每日"
  }
  // formData.value.pm = row.item1; //客户名称
  console.log('单个明细报表中查询每日整月整年三种报表', formData.value, daymonthyear);
  cxmx_dgpmzlbb()//单个品名质量报表
  setTimeout(() => {
    formData.value.cztj = route.query.bmmc; //要恢复原来的查询
    mytitle_bb.value = "手机端查询釉烧白选质量分析报表"
  }, 800);

}
//点击报表查询明细
const tableRowClick = (row) => {
  console.log('点击报表查询明细', formData.value, row);
  
};

//点击明细表关闭
const tableRowClick_mx = () => {
  drawer_RtoL.value = false;
};
const getmy_date = (tj) => {
  console.log(tj)
  switch (tj) {
    case 1:
      formData.value.rq = dateglobalfun.Lastdayone(); //上个月的第一天
      formData.value.jsrq = dateglobalfun.Lastdaynow(); //上个月的最后一天
      break;
    case 2:
      formData.value.rq = dateglobalfun.dayone(); //本月1号
      formData.value.jsrq = dateglobalfun.daynow(); //当日
      break;
    case 3:
      formData.value.rq = dateglobalfun.yearone(); //本年1号
      formData.value.jsrq = dateglobalfun.daynow(); //当日
      break;
    case 4:
      formData.value.rq = dateglobalfun.getBeforeDate(7); //前7日
      formData.value.jsrq = dateglobalfun.daynow(); //当日      
      break;
  }
};
const tableData_xm_gx = reactive([]);
const tableData_xm_gx_base = reactive([]);
const tableData_xm_gx_excel = reactive([]);
const formData = ref({//表格生成
  rq: dateglobalfun.getBeforeDate(30),
  dzkhid: "", //订制客户ID
  dzcpid: "", //订制产品ID
  jgkhid: "", //加工客户ID
  jgcpid: "", //加工产品ID
  azdz: "", //安装地址
  azqy: "", //安装区域
  bz: "", //备注
  bm: "", //编码
  clry: "", //测量人员
  dh: "", //单号
  dj: "", //单价
  dw: "", //单位
  dh: "", //电话
  gd: "", //高度
  gg: "", //规格
  je: "", //金额
  jsr: "", //经手人
  kdrq: "", //开单日期
  khdz: "", //客户地址
  khdh: "", //客户电话
  khmc: "", //客户名称
  kd: "", //宽度
  lldh: "", //联络电话
  lxr: "", //联系人
  lxrdh: "", //联系人电话
  mlhclmc: "", //面料或材料名称
  pm: "", //品名
  spbm: "", //商品编码
  spqm: "", //商品全名
  sjazrq: "", //实际安装日期
  sl: "", //数量
  shdz: "", //送货地址
  xdrq: dateglobalfun.getBeforeDate(0), //下单日期
  xh: "", //序号
  yqazrq: "", //要求安装日期
  zdr: "", //制单人
  cztj: "tjjczl", //操作条件
  yhmc: "", //token
  qxxm: "", //权限项目
  id: "1", //
  endrq: "", //结束日期
});

onMounted(() => {
  let bbmc = route.query.bmmc; //报表名称
  formData.value.yhmc = localStorage.getItem(myGtdatafun.XMMC + "yhtoken");
  formData.value.jsrq = dateglobalfun.daynow();
  console.log(bbmc); // 
  if (route.query.bmmc == "修删加工单") {
    cxtjLable1.value = "客户";
    cxtjLable2.value = "品名";
    cxtjLable3.value = "";
    isShowTJ1.value = true;
    isShowTJ2.value = true;
    isShowTJ3.value = false;
    mytitle_bb.value = "手机端查询修改删除加工单表头"
    formData.value.cztj = "手机端查询修改删除加工单";
    my_cxbb()
  }
  
  if (route.query.bmmc == "查库位") {
    isShowKwSelect.value=true;
    cxtjLable1.value = "货号";
    cxtjLable2.value = "库位";
    cxtjLable3.value = "";
    isShowTJ1.value = true;
    isShowTJ2.value = true;
    isShowTJ3.value = false;
    formData.value.zsgtj = "手机APP查询所有库位类别";
    formData.value.cztj = "手机APP查询所有库位类别";
    get_gwgx()
    setTimeout(() => {
      mytitle_bb.value = "查询库位库存"
      formData.value.zsgtj = "手机查询库位库存";
      formData.value.cztj = "手机查询库位库存";
    }, 1000);
  }
});

const get_gwgx_cxkh = async () => {
  await
    myGtdatafun
      .MygettableData("html_dysj_ZSG.php", formData.value)
      .then(function (response) {
        console.log(response, formData.value);
        if (response.length <= 0) { return }
        myloading.value = false
        drawer_RtoL_kh.value=true//显示客户列表
        tableData_ckrbb.length = 0; //数组置空,不然会重复
        tableData_ckrbb_base.length = 0; //数组置空,不然会重复
        response.forEach((item) => {
          tableData_ckrbb.push(item);
          tableData_ckrbb_base.push(item);
        })
        
      .catch(function (error) {
      });
    });
};

//筛选报表结果
const inputVal = ref("");
const hh_inputChange = async (e) => {
  tableData_xm_gx.length = 0; //先清空显示的数组
  tableData_xm_gx_base.forEach((item, index) => {
    if (item.item1.indexOf(inputVal.value.toUpperCase()) >= 0) {
      tableData_xm_gx.push(item); //再把选到的加入显示数组
    }
  });
};
//查询单个品名质量报表

//查询报表
const my_cxbb = () => {
  myloading.value = true
  console.log(formData.value);
  myGtdatafun
    .MygettableData("html_dysj_ZSG.php", formData.value)
    .then(function (response) {
      console.log(response, formData.value);
      myloading.value = false
      if (response.length > 0) {
        tableData_xm_gx.length = 0; //数组置空,不然会重复
        tableData_xm_gx_base.length = 0; //数组置空,不然会重复
        response.forEach((item) => {
          dialogFormVisible_bbtj.value = false;
          tableData_xm_gx.push(item);
          tableData_xm_gx_base.push(item);
        });
        setTimeout(() => {
          // response.forEach((item) => {
          //   tableData_xm_gx_excel.push(item);
          // });

          response.map(v => {
            // 直接删除不必要的元素
            delete v.item9//id
            return v
          }).forEach((item) => {
            tableData_xm_gx_excel.push(item);
          });
          // console.log(tableData_xm_gx_excel);
        }, 500);
      }
    });
};

const router = useRouter();
const toHome = () => {
  router.go(-1);
};

const toexcel =()=>{
  // 以下4句可行
  /*
  const data = XLSX.utils.json_to_sheet(tableData_xm_gx)//此处tableData.value为表格的数据
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, data, 'test-data')//test-data为自定义的sheet表名
  XLSX.writeFile(wb, 'test.xlsx')//test.xlsx为自定义的文件名
  货号,应送货数量,内盒,外箱,英文品名,工厂单号,客人单号,日期
*/
  let titleArr = []
  let bt=''
  if(route.query.bmmc == '查加工单') {
    bt = '得秀窗帘加工单'
    titleArr = titleArr.concat( ['开单日期', '客户名称', '品名', '单位', '数量', '单价', '金额', '送货地址'])//表头中文名
  }
  exportExcel(tableData_xm_gx_excel, formData.value.rq+'到'+ formData.value.xdrq +bt,
   titleArr, 
  formData.value.rq + '到'+ formData.value.xdrq +bt);
}

/*
封装一个exportExcel方法
    * @description:
    * @param {Object} json 服务端发过来的数据

    * @param {String} name 导出Excel文件名字
   
    * @param {String} titleArr 导出Excel表头
   
    * @param {String} sheetName 导出sheetName名字

    * @return:
    */
function exportExcel(json, name, titleArr, sheetName) {
  /* convert state to workbook */
  var data = new Array();
  var keyArray = new Array();
  const getLength = function (obj) {
    var count = 0;
    for (var i in obj) {
      if (obj.hasOwnProperty(i)) {
        count++;
      }
    }
    return count;
  };
  for (const key1 in json) {
    if (json.hasOwnProperty(key1)) {
      const element = json[key1];
      var rowDataArray = new Array();
      for (const key2 in element) {
        if (element.hasOwnProperty(key2)) {
          const element2 = element[key2];
          rowDataArray.push(element2);
          if (keyArray.length < getLength(element)) {
            keyArray.push(key2);
          }
        }
      }
      data.push(rowDataArray);
    }
  }
  // keyArray为英文字段表头
  data.splice(0, 0, keyArray, titleArr);
  // console.log('data', data);
  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  // 此处隐藏英文字段表头
  var wsrows = [{ hidden: true }];
  ws['!rows'] = wsrows; // ws - worksheet
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  /* generate file and send to client */
  XLSX.writeFile(wb, name + '.xlsx');
}
</script>
<style scoped>
/*  */
html,
body {
  height: 100%;
}

.wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

header,
footer {
  height: 40px;
  line-height: 40px;
  background-color: #d8d8d8;
  text-align: center;
}

section {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  overflow: auto;
  /* winphone8和android4+ */
  -webkit-overflow-scrolling: touch;
  /* ios5+ */
}

/*  */

.el-select {
  width: 300px;
}

.el-input {
  width: 300px;
}

.myform {
  display: flex;
  justify-content: center;
  margin: 20px;
  background-color: #000;
}

.mybutton {
  display: flex;
  justify-content: center;
}

.mypm {
  display: inline-block;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
  color: #222;
  font-size: 18px;
  font-weight: 700;
}

:deep(.el-form-item__label) {
  color: #222;
  font-size: 16px;
  font-weight: 600;
}

:deep(tr.el-table__row.warningrow) {
  background-color: yellow;
  color: #999;
}

:deep(tr.el-table__row.redrow) {
  background-color: rgb(243, 5, 5);
  color: white;
}

.el-form-item {
  margin-bottom: 5px !important;
}

.el-dialog {
  margin-top: 5vh !important;
}
</style>